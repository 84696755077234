import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
@Component({
  selector: 'bpc-root',
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet>`,
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
      }
    `,
  ],
  standalone: true,
})
export class AppComponent {
  loading = false;

  constructor(private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.saveCurrentPageMode();
      }
    });
    this.updateToken();
  }

  saveCurrentPageMode() {
    // sprawdza tryb strony - pomaga podczas zapisywania ostatniej strony na paginacji
    if (this.router.url.includes('edit')) {
      this.savePageInBrowser('edit');
    } else if (this.router.url.includes('view')) {
      this.savePageInBrowser('view');
    } else if (this.router.url.includes('add')) {
      this.savePageInBrowser('add');
    }
  }

  savePageInBrowser(mode: string) {
    localStorage.setItem('lastPageMode', mode);
  }

  updateToken(): void {
    setInterval(() => {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          localStorage.setItem('authToken', idToken);
        })
        .catch(function (error) {
          console.log('err', error);
        });
    }, 300000);
  }
}
