import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { APP_ROUTES } from '@app/app-routing';
import { AppComponent } from '@app/app.component';
import { AuthInterceptor } from '@app/features/auth/auth.interceptor';
import { HttpErrorInterceptor } from '@app/features/components/inceptors/error.inceptor';
import { DirtyFormGuard } from '@app/features/guards/form-guard/dirty-form.guard';
import { AllRolesGuard } from '@app/features/guards/role-guards/all-roles.guard';
import { AdminSuperAdminExpertGuardianClientGuard } from '@app/features/guards/role-guards/superadmin-admin-expert-guardian-client.guard';
import { AdminSuperAdminExpertGuardianGuard } from '@app/features/guards/role-guards/superadmin-admin-expert-guardian-role.guard';
import { AdminSuperAdminExpertGuard } from '@app/features/guards/role-guards/superadmin-admin-expert.guard';
import { AdminSuperAdminGuardianGuard } from '@app/features/guards/role-guards/superadmin-admin-guardian.guard';
import { AdminSuperAdminOwnerClientExpertGuard } from '@app/features/guards/role-guards/superadmin-admin-owner-client-expert.guard';
import { AdminSuperAdminGuard } from '@app/features/guards/role-guards/superadmin-admin-role.guard';
import * as Sentry from '@sentry/angular-ivy';
import { pl as PL } from 'date-fns/locale';
import { NZ_I18N, pl_PL, NZ_DATE_LOCALE, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';

import { environment } from './environments/environment';

registerLocaleData(localePl);

if (environment.sentry) {
  Sentry.init({
    dsn: 'https://c085c774e840476bbe0d47315b865a6a@o1259153.ingest.sentry.io/6433581',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['https://moje.bpc.pl'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    importProvidersFrom(RouterModule.forRoot([...APP_ROUTES])),
    importProvidersFrom(AdminSuperAdminGuard),
    importProvidersFrom(AdminSuperAdminExpertGuard),
    importProvidersFrom(AdminSuperAdminExpertGuardianGuard),
    importProvidersFrom(AdminSuperAdminExpertGuardianClientGuard),
    importProvidersFrom(AdminSuperAdminGuardianGuard),
    importProvidersFrom(AdminSuperAdminOwnerClientExpertGuard),
    importProvidersFrom(AllRolesGuard),
    importProvidersFrom(NzMessageService),
    importProvidersFrom(AngularFireModule.initializeApp(environment.firebase)),
    importProvidersFrom(AngularFirestoreModule),
    importProvidersFrom(AngularFireAuthModule),
    importProvidersFrom(AngularFireAnalyticsModule),
    importProvidersFrom(BrowserModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(FormsModule),
    importProvidersFrom(HttpClientModule),
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: NZ_I18N, useValue: pl_PL },
    { provide: NZ_DATE_LOCALE, useValue: PL },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpErrorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    CurrencyPipe,
    DatePipe,
    DirtyFormGuard,
    ScreenTrackingService,
    UserTrackingService,
    NzModalService,
    NzNotificationService,
  ],
}).catch((err) => console.error(err));
