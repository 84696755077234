import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlRedirectService {
  private readonly router = inject(Router);
  public redirectUrl = signal<string>(null);

  public redirectToPreviousUrl(): void {
    if (this.redirectUrl()) {
      this.router.navigate([this.redirectUrl()]).then(() => {
        this.redirectUrl.set(null);
      });
    }
  }
}
