// auth.interceptor.ts
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  // Get token from localStorage
  const token = localStorage.getItem('authToken');

  if (token) {
    // Clone the request and add the token
    const authReq = req.clone({
      setHeaders: {
        Authorization: `${token}`,
      },
    });
    return next(authReq);
  }

  return next(req);
};
