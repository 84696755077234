import { Routes } from '@angular/router';
import { AuthGuard } from '@app/features/auth/auth.guard';

import { AppComponent } from './app.component';
import { RoleGuard } from './features/auth/auth-role.guard';
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'app',
        loadChildren: () => import('@app/modules/pages/pages-routing').then((m) => m.APP_ROUTES),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'login',
        loadChildren: () => import('@app/modules/login/login-routing').then((m) => m.LOGIN_ROUTING),
      },
      {
        path: '**',
        canActivate: [RoleGuard],
      },
    ],
  },
];
