import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/features/auth/auth.service';
import { map, tap } from 'rxjs/operators';
import { UrlRedirectService } from '../services/url-redirect.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private urlRedirectService = inject(UrlRedirectService);
  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.urlRedirectService.redirectUrl.set(state.url);
    return this.authService.authInfo$.pipe(
      map((x) => x.isLoggedIn()),
      tap((allowed) => {
        if (!allowed) {
          this.router.navigate(['/login']);
        }
      }),
    );
  }
}
