import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagesService } from '@app/modules/pages/services/pages.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { User } from '../interfaces/user.types';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private url = `${environment.url}login`;
  private userUrl = `${environment.url}users`;

  constructor(private http: HttpClient, private pagesService: PagesService) {}

  login(uid: string): Observable<User> {
    return this.http.post<User>(`${this.url}`, { uid: uid }).pipe(catchError((err) => this.pagesService.handleError(err)));
  }

  updateUserRole() {
    return this.http
      .put(`${this.userUrl}/afterLogin/${localStorage.getItem('id')}`, {
        loginAs: localStorage.getItem('loginAs'),
      })
      .pipe(catchError((err) => this.pagesService.handleError(err)));
  }
}
