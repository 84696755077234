import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getIdToken } from 'firebase/auth';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
@Component({
  selector: 'bpc-root',
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet>`,
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
      }
    `,
  ],
})
export class AppComponent {
  loading = false;
  constructor(
    private router: Router,
    private auth: Auth,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.saveCurrentPageMode();
      }
    });
    this.setupTokenRefresh();
  }

  saveCurrentPageMode() {
    // sprawdza tryb strony - pomaga podczas zapisywania ostatniej strony na paginacji
    if (this.router.url.includes('edit')) {
      this.savePageInBrowser('edit');
    } else if (this.router.url.includes('view')) {
      this.savePageInBrowser('view');
    } else if (this.router.url.includes('add')) {
      this.savePageInBrowser('add');
    }
  }

  savePageInBrowser(mode: string) {
    localStorage.setItem('lastPageMode', mode);
  }

  setupTokenRefresh(): void {
    setInterval(() => {
      const user = this.auth.currentUser;
      if (user) {
        getIdToken(user, true)
          .then((idToken) => {
            localStorage.setItem('authToken', idToken);
          })
          .catch((error) => {
            console.log('Token refresh error:', error);
          });
      }
    }, 300000); // 5 minutes
  }
}
