import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/features/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AllRolesGuard implements CanActivate {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const canAccess: boolean = await this.authService.isEnabledForUsers$(['isAdmin', 'isSuperAdmin', 'isGuardian', 'isExpert', 'isClient', 'isOwner', 'isIntermediarie', 'isPartner']);
    if (!canAccess) {
      this.router.navigate(['/app']);
      return false;
    }
    return true;
  }
}
