import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { throwError as observableThrowError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PagesService {
  private basePath = '/uploads';
  noti: NzNotificationService;
  constructor(private notification: NzNotificationService) {
    this.noti = notification;
  }

  pushUpload(upload, destination = this.basePath, fileName = upload.file.name) {
    return new Promise((resolve) => {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`${destination}/${fileName}`).put(upload.file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          upload.progress = (snapshot['bytesTransferred'] / snapshot['totalBytes']) * 100;
        },
        () => {},
        () => {
          resolve(true);
          upload.name = upload.file.name;
        },
      );
    });
  }

  handleError(err) {
    if (typeof err === 'object') {
      if (err.error?.message) {
        this.notification.error('Ups.. Coś poszło nie tak.', err.error?.message);
      } else {
        localStorage.setItem('returnPage', window.location.pathname);
        this.notification.error('Ups.. Coś poszło nie tak.', 'Posiadasz starą wersje aplikacji. Wyłącz i włącz przegladarke');
      }
    }

    if (typeof err === 'object' && err.status === 401) {
      localStorage.setItem('returnPage', window.location.pathname);
      this.notification.error('Ups.. Coś poszło nie tak.', 'Zostałeś wylogowany. Zapisaliśmy błąd i skontaktujemy sie z Tobą.');
      window.location.replace('/login');
    }

    return observableThrowError('Błąd servisu', err);
  }
}
