import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { APP_ROUTES } from '@app/app-routing';
import { AppComponent } from '@app/app.component';
import { authInterceptor } from '@app/features/auth/auth.interceptor';
import { DirtyFormGuard } from '@app/features/guards/form-guard/dirty-form.guard';
import { AllRolesGuard } from '@app/features/guards/role-guards/all-roles.guard';
import { AdminSuperAdminExpertGuardianClientGuard } from '@app/features/guards/role-guards/superadmin-admin-expert-guardian-client.guard';
import { AdminSuperAdminExpertGuardianGuard } from '@app/features/guards/role-guards/superadmin-admin-expert-guardian-role.guard';
import { AdminSuperAdminExpertGuard } from '@app/features/guards/role-guards/superadmin-admin-expert.guard';
import { AdminSuperAdminGuardianGuard } from '@app/features/guards/role-guards/superadmin-admin-guardian.guard';
import { AdminSuperAdminOwnerClientExpertGuard } from '@app/features/guards/role-guards/superadmin-admin-owner-client-expert.guard';
import { AdminSuperAdminGuard } from '@app/features/guards/role-guards/superadmin-admin-role.guard';
import * as Sentry from '@sentry/angular';
import { TraceService } from '@sentry/angular';
import { pl as PL } from 'date-fns/locale';
import { NZ_DATE_CONFIG, NZ_DATE_LOCALE, NZ_I18N, pl_PL } from 'ng-zorro-antd/i18n';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from './environments/environment';

registerLocaleData(localePl);

if (environment.sentry) {
  Sentry.init({
    dsn: 'https://c085c774e840476bbe0d47315b865a6a@o1259153.ingest.sentry.io/6433581',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['https://moje.bpc.pl'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    importProvidersFrom(RouterModule.forRoot([...APP_ROUTES])),
    importProvidersFrom(AdminSuperAdminGuard),
    importProvidersFrom(AdminSuperAdminExpertGuard),
    importProvidersFrom(AdminSuperAdminExpertGuardianGuard),
    importProvidersFrom(AdminSuperAdminExpertGuardianClientGuard),
    importProvidersFrom(AdminSuperAdminGuardianGuard),
    importProvidersFrom(AdminSuperAdminOwnerClientExpertGuard),
    importProvidersFrom(AllRolesGuard),
    importProvidersFrom(NzMessageService),
    importProvidersFrom(BrowserModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(FormsModule),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    provideHttpClient(withInterceptors([authInterceptor])),
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: NZ_I18N, useValue: pl_PL },
    { provide: NZ_DATE_LOCALE, useValue: PL },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    CurrencyPipe,
    DatePipe,
    DirtyFormGuard,
    ScreenTrackingService,
    UserTrackingService,
    NzModalService,
    NzNotificationService,
  ],
}).catch((err) => console.error(err));
