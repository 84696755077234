import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@app/features/auth/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  private authService = inject(AuthService);

  canActivate(): Observable<boolean> {
    return this.authService.authInfo$.pipe(
      map(() => this.hasUserRole()),
      tap((hasRole) => {
        if (!hasRole) {
          this.authService.logout();
        }
      }),
    );
  }

  private hasUserRole(): boolean {
    return Object.values(this.authService.getRoles()).some((role) => role === true);
  }
}
